import {

    Accordion,
    AccordionItem,
    IconButton,
} from '@carbon/react';
import { InformationSquare } from '@carbon/icons-react';
import { useCallback, useEffect } from 'react';
import ExpertCard from './basic/ExpertCard';

function ResponseForFindDoctorsAccordion({ llmRes, webChatInstance, userInput }) {


    useEffect(() => {
        if (llmRes === 'TIME_OUT') {
            const triggered = sessionStorage.getItem(userInput);
            if (!triggered) {
                sessionStorage.setItem(userInput, true);
                // console.log('wait 31s ...');
                setTimeout(function () {
                    webChatInstance.send({ input: { text: userInput } });
                }, 31000);
            }
        }
    }, [llmRes, userInput, webChatInstance]);

    const onMoreAboutDoctorClick = useCallback(
        (text) => {
            webChatInstance.send({ input: { text: `Tell me more about ${text}` } });
        },
        [webChatInstance],
    );

    const enhancedExperts = llmRes?.experts?.map((expert, index) => {
        return {
            ...expert,
            id: index + 1,
            action: <IconButton label={`more`} kind='ghost' onClick={() => {
                onMoreAboutDoctorClick(expert.name);
            }}>
                <InformationSquare />
            </IconButton>
        };
    });

    return <>
        <br />
        {llmRes?.experts && <Accordion>
            {
                enhancedExperts.map((expert, index) => {
                    return <AccordionItem title={expert.name} open={true}>
                        <ExpertCard expert={expert} webChatInstance={webChatInstance} />
                    </AccordionItem>
                })
            }
        </Accordion>}
    </>
}


export default ResponseForFindDoctorsAccordion;