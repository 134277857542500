import { Button, Column, Grid } from "@carbon/react";

function TrialCard({ trial, webChatInstance }) {

  const showActions = false;

  return <Grid>
    <Column sm="100%" md="100%" lg="100%" xlg="100%">
      <div>
        <h6>{trial.title}</h6><br />
        <h6>{trial.pi}</h6>
        <br />
        {showActions && <>
          <Button label={`more`} size="sm" kind="tertiary" onClick={() => {
            console.log('Button clicked');

          }}>
            More...
          </Button></>}
      </div>
    </Column>
  </Grid>
}

export default TrialCard;