import { Button, Column, Grid } from "@carbon/react";
import { useCallback } from "react";

function ExpertCard({ expert, webChatInstance }) {

  const showActions = true;

  const onMoreAboutDoctorClick = useCallback(
    (text) => {
      webChatInstance.send({ input: { text: `Tell me more about ${text}` } });
    },
    [webChatInstance],
  );

  const imgUrl = expert.portraitUrl ? expert.portraitUrl : "https://placehold.co/100";

  return <Grid>
    <Column sm="100%" md="50%" lg="25%" xlg="25%">
      <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }} >
        <img src={imgUrl} alt="portrait" style={{ width: "122px", height: "150px" }} />
      </div>
      <br />
    </Column>
    <Column sm="100%" md="50%" lg="75%" xlg="75%">
      <div>
        <a rel="noreferrer" target="_blank" href={expert.url} >{expert.name}</a> <br />
        {expert.title}<br />
        {expert.phone}<br />
        {expert.email}<br /><br />
        {showActions && <>
          <Button label={`more`} size="sm" kind="tertiary" onClick={() => {
            onMoreAboutDoctorClick(expert.name);
          }}>
            More...
          </Button></>}
      </div>
    </Column>
  </Grid>
}

export default ExpertCard;