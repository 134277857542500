import {
    Accordion,
    AccordionItem,
} from '@carbon/react';
import TrialCard from './basic/TrialCard';
import { useEffect } from 'react';

function ResponseForFindTrials({ llmRes, webChatInstance, userInput }) {

    const enhancedTrials = llmRes?.trials?.map((trial, index) => {
        return {
            ...trial,
            id: index + 1
        };
    });

    // const onRetry = useCallback(
    //     () => {
    //         setTimeout(function () {
    //             webChatInstance.send({ input: { text: userInput } });
    //         }, 10000);

    //     },
    //     [webChatInstance, userInput],
    // );

    useEffect(() => {
        if (llmRes === 'TIME_OUT') {
            const triggered = sessionStorage.getItem(userInput);
            if (!triggered) {
                sessionStorage.setItem(userInput, true);
                // console.log('wait 31s ...');
                setTimeout(function () {
                    webChatInstance.send({ input: { text: userInput } });
                }, 31000);
            }
        }
    }, [llmRes, userInput, webChatInstance]);

    return <>
        <br />

        {llmRes?.trials && <Accordion>
            {
                enhancedTrials?.map((trial, index) => {
                    return <AccordionItem key={index} title={`${trial['nct']} (${trial['status']})`} open={true}>
                        <TrialCard trial={trial} webChatInstance={webChatInstance} />
                    </AccordionItem>
                })
            }
        </Accordion>}
        {/* {llmRes === 'TIME_OUT' && <IconButton label={`retry`} kind='ghost' onClick={onRetry}>
            <Reset />
        </IconButton>} */}
    </>
}


export default ResponseForFindTrials;